
import React, { useEffect, useState } from 'react';
import './css/referalModal.css';
import { getloggedInUser } from '../../Utilities/getloggedInUser';
import { useLocation } from 'react-router-dom';

const ReferralModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { loggedInUser } = getloggedInUser()
  const location = useLocation();

  const [enableReferralFactory, setEnableReferralFactory] = useState(false);

  const updateReferralFactory = () => {
    const company = JSON.parse(localStorage.getItem('company') || '{}');
    setEnableReferralFactory(company.enable_referral_factory || false);
  };

  useEffect(() => {
    updateReferralFactory();
  }, [location.pathname]);

  const apiResponse = {
    url: "https://embed.referral-factory.com/c7U0EX5S?source=Widget",
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://referral-factory.com/assets/js/widget.js?code=c7U0EX5S';
    script.id = 'rf-script'
    script.async = true;

    script.onload = () => {
      if (window.ReferralFactory) {
        window.ReferralFactory.init({
          campaign: 'c7U0EX5S',
          user: {
            first_name: `${loggedInUser?.first_name}`,
            email: `${loggedInUser?.email}`,
            last_name: `${loggedInUser?.last_name}`
          },
        });
      }
    };

    document.head.appendChild(script);

    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
      document.head.removeChild(script);
    };
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <>
      {(!isOpen && enableReferralFactory) && (
        <button className="refer-button" onClick={handleOpenModal}>
          <span className='cursor-pointer'>
            <img src='/imgs/chowmill-logo.png' height="35" width="25" alt="Refer & Earn $350" />
            <span className='ml-3'><strong>Refer & Earn $350</strong></span>
          </span>
        </button>
      )}
      {isOpen && (
        <div className="refer-modal-overlay">
          <div className="refer-modal-content">
            <div className='refer-modal-header'>
              <button className='btn p-3 refer-modal-close-btn' onClick={handleCloseModal}>
                <i className="fa-regular fa-xmark fa-2x"></i>
              </button>
              <div className='mx-2 refer-modal-heading'>Refer Friends </div>
            </div>
            <iframe
              src={apiResponse.url}
              title="Referral Factory Widget"
              style={{ width: '100%', height: '700px', border: 'none' }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ReferralModal;
